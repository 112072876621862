import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link } from "gatsby"

// import Logo from '../../images/logo1.jpeg'
import theme from '../ui/theme.js'
import Propriete from '../../images/propriete.png'
import Emprunt from '../../images/emprunt.png'
import Logement from '../../images/logement.png'
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  proprieteContainer: {
    width: "3rem"
  },
  BesoinsContainer: {
    width: 'auto',
    height: "20rem",
    marginTop: "3rem",
  },
  imgContainer: {
    marginRight: "1rem",
  },
  besoinsCard: {
    margin: "1.7rem"
  },
  besoinItem: {
    width: '12rem',
    height: 'auto',
    backgroundColor: "white",
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`
    },
  },
  img: {
    [theme.breakpoints.down('xs')]: {
      width: '2rem'
    }
  }
}))

const besoins = [
  {
    img: Propriete, link: "../../annonces", texte: <Typography style={{ color: theme.palette.secondary.main }} variant="h1">
      ACCÉDER À  <span style={{ color: theme.palette.common.black }}> LA PROPRIÉTÉ</span>
    </Typography>
  },
  {
    img: Emprunt, link: "https://www.credicim.com/credit-nantes-ouest/", target: "_blank", texte: <Typography style={{ color: theme.palette.secondary.main }} variant="h1">
      SIMULER UN <span style={{ color: theme.palette.common.black }}>EMPRUNT AVEC UN COURTIER PROFESSIONNEL</span>
    </Typography>
  },
  {
    img: Logement, link: "../../annonces", texte: <Typography style={{ color: theme.palette.secondary.main }} variant="h1">
      RECHERCHER <span style={{ color: theme.palette.common.black }}> VOTRE FUTUR LOGEMENT</span>
    </Typography>
  }
]


export default function Besoins() {
  const classes = useStyles()
  const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    // <Card elevation={matchesXS ? 0 : 2} classes={{ root: classes.besoinsCard }}>
    <Grid
      container
      alignContent="center"
      alignItems="flex-start"
      justifyContent="space-around"
      classes={{ root: classes.BesoinsContainer }}
      direction="row">
      {besoins.map((item, i) => (
        <Button key={i} onClick={() => { i != 1 ? navigate(item.link) : window.open(item.link, "_blank") }} classes={{ root: classes.besoinItem }}>
          <Grid item container key={item.texte} target={i == 1 && "_blank"} alignContent="center" alignItems="center" classes={{ root: classes.proprieteContainer }} direction="column">
            <Grid item classes={{ root: classes.imgContainer }}>
              <img className={classes.img} src={item.img} alt="Accéder à la propriété" />
            </Grid>
            <Grid item>
              <Typography style={{ textTransform: matchesXS ? 'lowercase' : 'none', textDecoration: 'none', fontSize: matchesXS && '0.9rem' }}>
                {item.texte}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      ))}
    </Grid>
    // </Card>
  )
}
