import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import RoomIcon from '@material-ui/icons/Room'
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from '@material-ui/core/useMediaQuery'

import theme from '../ui/theme.js'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '30rem',
    },
    estimeButton: {
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.main,
        height: "3rem",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        }
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function SearchInputBase({callActionText}) {
    const classes = useStyles();
    const inputPlaceholder = 'Rechercher une adresse'

    const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'))

    return (
        <Paper component="form" className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="menu">
                <RoomIcon />
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder={inputPlaceholder}
                inputProps={{ 'aria-label': inputPlaceholder }}
            />
            <Divider className={classes.divider} orientation={matchesMD ? "horizontal" : "vertical"} />
            <Button onClick={() => {navigate("../../estimation")}} classes={{ root: classes.estimeButton }}>
                <Typography style={{color: theme.palette.common.white}} variant="body1">{callActionText}</Typography>
            </Button>
        </Paper>
    );
}