import { Button, Card, CardMedia, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link } from 'gatsby'
import React from 'react'
import AnnonceFrameGrid from './annonce-frame-grid'
import Carousel from 'better-react-carousel'
import { navigate } from 'gatsby'
import Mur from "../../images/mur.jpg"


const useStyles = makeStyles(theme => ({

    annonceContainer: {
        backgroundImage: `url(${Mur})`,
        backgroundSize: "cover",
        width: "100%",
        marginTop: "2rem",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        [theme.breakpoints.only("xl")]: {
            "& > *": {
                marginRight: "calc((100% - (20rem * 5)) / 4)",
                marginBottom: "3rem",
            },
            "& > :nth-child(5n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("lg")]: {
            "& > *": {
                marginRight: "calc((100% - (20rem * 4)) / 3)",
                marginBottom: "3rem",
            },
            "& > :nth-child(4n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("md")]: {
            "& > *": {
                marginRight: "calc((100% - (20rem * 3))/2)",
                marginBottom: "3rem",
            },
            "& > :nth-child(3n)": {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only("sm")]: {
            "& > *": {
                marginRight: "calc(100% - (25rem * 2))",
                marginBottom: "3rem",
            },
            "& > :nth-child(n)": {
                marginRight: 0,
            },
            width: '100%'
        },
    },
    blockTitle: {
        margin: "2rem",
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        paddingLeft: "2rem"
    },
    carouselContainer: {
        width: "90%",
        position: "relative"
    },
    buttonAnnonces: {
        position: "absolute",
        right: "1rem",
        marginTop: "2rem"
    }
}))


const AnnoncesVedettes = ({ annoncesVedettes }) => {
    const classes = useStyles()
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme => theme.breakpoints.down("md"))
    const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

    const responsiveLayout = [
        {
            breakpoint: 600,
            cols: 1,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 5000
        },
        {
            breakpoint: 960,
            cols: 2,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 5000
        },
        {
            breakpoint: 1280,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 10000
        },
        {
            breakpoint: 1920,
            cols: 3,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 10000
        }
    ]

    return (
        <Grid container alignItems='flex-start' direction='column'>
            <Divider style={{ color: theme.palette.common.black }} />
            <Grid item classes={{ root: classes.blockTitle }}>
                    <Typography style={{ color: theme.palette.primary.main }} variant='h1'>
                        Nos coups de coeur
                    </Typography>
                </Grid>
            <Grid
                item
                container
                direction={matchesSM ? "row" : "column"}
                alignItems={matchesSM ? "center" : "flex-start"}
                alignContent="center"
                justifyContent="center"
                classes={{ root: classes.annonceContainer }}>
                <Grid item classes={{ root: classes.carouselContainer }}>
                    <Carousel responsiveLayout={responsiveLayout} cols={3} style={{ marginBottom: "2rem" }} rows={1} loop>
                        {annoncesVedettes
                            .map((item, i) => (
                                <Carousel.Item key={i}>
                                    <AnnonceFrameGrid
                                        annonce={item}
                                    />
                                </Carousel.Item>
                            ))}
                    </Carousel>
                    <Button onClick={() => { navigate("annonces") }} classes={{ root: classes.buttonAnnonces }}>
                        <Typography style={{ color: `${theme.palette.secondary.main}` }}>Voir toutes les annonces</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    )

}
export default AnnoncesVedettes