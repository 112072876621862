import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { useStaticQuery, graphql } from "gatsby"
import theme from "../ui/theme.js"


const useStyles = makeStyles(theme => ({
    serviceItemContainer: {
        height: "auto",
        width: "18rem",
        borderRadius: "0.5rem",
       
        /* border: "2px solid #D95D39", */
        [theme.breakpoints.down('sm')]: {
           
            width: "12rem",
            padding: "1rem"
        },
        [theme.breakpoints.down('xs')]: {
          
            width: "100%",
            padding: "1rem"
        }
    },
    serviceBody: {
        color: theme.palette.text.secondary
    },
    serviceTitle: {
        color: "#202C59"
    },
    serviceImg: {
        height: "13rem",
        [theme.breakpoints.down('md')]: {
            height: "7rem",
        },
        [theme.breakpoints.down('xs')]: {
            height: "10rem",
        }
    },
    servicePaper: {
        [theme.breakpoints.down('md')]: {
            margin: "1rem"
        }
    }
}))

export default function Services() {
    const classes = useStyles()
    // const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    const { allStrapiBlockPointFort } = useStaticQuery(graphql`
    query GetPointForts {
        allStrapiBlockPointFort {
            nodes {
              id
              titre
              image {
                localFile {
                    id
                    url
                  }
              }
              description
            }
          }
      }
    `);

    const services = allStrapiBlockPointFort.nodes.map(item => {
        const service = {
            title: item.titre,
            body: item.description
        }
        if (item.image) {
            service.img = item.image.localFile.url
        }
        return service;
    });

    return (
        <Grid container 
            alignItems="center"
            justifyContent="space-around"
            direction={matchesSM ? "column" : "row"}>
            {services.map(service => (
                <Paper key={service.title} classes={{root: classes.servicePaper}} elevation={3} >
                    <Card classes={{ root: classes.serviceItemContainer }} >
                        {service.img && <CardMedia classes={{root: classes.serviceImg}}
                            component="img"
                            image={service.img}
                            alt="service" />
                        }
                        <CardContent>
                            <Typography gutterBottom variant="h1" component="div">
                                {service.title}
                            </Typography>
                            <Typography style={{color: "#1D2424"}} variant="body1" classes={{ root: classes.serviceBody }}>
                                {service.body}
                            </Typography>
                        </CardContent>
                    </Card>
                </Paper>
            ))}
        </Grid>
    )
}