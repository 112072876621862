import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Immo from "../../images/real-estate.gif"
import SearchInputBase from './searchInput'
import { loader } from '../ui/loaderComponent';
import { useStaticQuery, graphql } from "gatsby"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"

import heroBG from "../../images/hero.jpg"



const useStyles = makeStyles(theme => ({
    heroContainer: {
        paddingTop: "4rem",
        backgroundImage: `url(${heroBG})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.primary.main,
        height: "22rem",
        marginBottom: "2rem",
        [theme.breakpoints.down('xs')]: {
            padding: "6rem 0 1rem 2rem",
            width: "100%",
            height: "18rem"
        }
    },
    // heroRow: {
    //    // width: "80%"
    // },
    // imageContainer: {
        // height: "20rem",

        // [theme.breakpoints.down('md')]: {
        //     height: "10rem",
        //     width: "10rem"
        // }

    // },
    // heroImage: {
    //     height: "20rem",
    //     [theme.breakpoints.down('xs')]: {
    //         height: "16rem"
    //     }
    // },
    // plateformeContainer: {


    // },
    estimeGratuitButton: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        "&:hover":{
            backgroundColor: theme.palette.primary.light,
        }
    },
    buttonText: {
        textTransform: "none",
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            fontSize: "1rem"
        }
    },
    herodescription: {
        margin: "1rem 0",
        width: "30rem",
        [theme.breakpoints.down('xs')]: {
            width: "15rem",
            margin: "1rem 0"
        },
        [theme.breakpoints.down('md')]: {
            width: "20rem"
        },
    }
}))


export default function HeroBlock() {
    const classes = useStyles()
    const theme = useTheme()
    // const {heroBlockContent} = useHeroBlockContent()

    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    const { allStrapiBlockEnteteAccueil } = useStaticQuery(graphql`
    query GetEntete {
        allStrapiBlockEnteteAccueil {
          edges {
            node {
              titre
              description
              textButtonAction
            }
          }
        }
      }
    `);

    const heroBlockContent = allStrapiBlockEnteteAccueil.edges.map(item => ({
        title: item.node.titre,
        description: item.node.description,
        callToACtionText: item.node.textButtonAction

    }))[0]

    return (
        <Grid container classes={{ root: classes.heroContainer }}>
            <Grid item container classes={{ root: classes.heroRow }} alignItems="center" justifyContent="space-around" direction="row">
                {heroBlockContent ? <Grid item>
                    <Grid
                        item
                        container
                        classes={{ root: classes.plateformeContainer }}
                        alignItems="flex-start"
                        justifyContent="space-around" direction="column">
                        <Grid item classes={{ root: classes.heroTitle }}>
                            <Typography style={{color: theme.palette.common.white}} variant="h1" >{heroBlockContent.title}</Typography>
                        </Grid>
                        <Grid item classes={{ root: classes.herodescription }}>
                            <Typography style={{color: theme.palette.common.white}} variant="body1">{heroBlockContent.description}</Typography>
                        </Grid>
                        <Grid item>
                            {/* {matchesSM ?  */}
                            <Button onClick={()=>{navigate("/estimation")}} size="large" classes={{ root: classes.estimeGratuitButton }}>
                                <Typography variant="body1"><span className={classes.buttonText}>Estimer gratuitement mon bien</span></Typography>
                            </Button>
                            {/* : <SearchInputBase callActionText={heroBlockContent.callToACtionText} />} */}
                        </Grid>
                    </Grid>
                </Grid> : loader}
                <Grid item classes={{ root: classes.imageContainer }}>
                    {/* <img className={classes.heroImage} src={Immo} alt="Immo" /> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

