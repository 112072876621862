import React from "react"
import Layout from '../components/ui/layout'
import HeroBlock from "../components/accueil/heroBlock"
import Services from "../components/accueil/services"
// import Estimation from "../components/accueil/estimation"
import SEO from "../components/ui/seo"
import Grid from "@material-ui/core/Grid"

import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"


import Besoins from "../components/accueil/besoins"
import AnnoncesVedettes from "../components/annonce-list/annonces-vedettes"


const useStyles = makeStyles(theme => ({
  main: {
    paddingTop: "2rem",

  }
}))

const IndexPage = ({ pageContext }) => {

  const classes = useStyles();
console.log(pageContext.annoncesVedettes)

  return (
    <Layout >
      <SEO title="Accueil" />
      <HeroBlock />
      <Container maxWidth={false} classes={{ root: classes.main }}>
        <Grid container direction="column" spacing={3}>
        <Services />
        <AnnoncesVedettes annoncesVedettes={pageContext.annoncesVedettes} />
        <Besoins />
        </Grid>
      </Container>
    </Layout>
  )
}
export default IndexPage